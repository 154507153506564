import { Query, Blot } from "@avvoka/editor"
import { getObjectEntries } from "@avvoka/shared";
import { deltaToSanitisedHtml } from "../../features/editor/utils";
import { LoadingHelpers} from "../../features/loading";
import { getURLTab, setURLTab } from "../../features/dom_utils";
import { stopAndPrevent } from "../../features/_abstract/utils/event";
import { getElementByIdStrict } from "../../features/_abstract/utils/dom";
import { validationDialog } from "./validations";

window.avv_select_tab_listeners = []
window.avv_before_select_tab_listeners = []
window.avv_opened_tabs = new Set()

/**
 * @param {"questionnaire" | "document" | "operations" | "doc-rules" | "diagnostics" | "livedemo" | "notes" | "advanced" | "settings"} tabIdentification
 * @param {"questionnaire" | "document" | "operations" | "doc-rules" | "diagnostics" | "livedemo" | "notes" | "advanced" | "settings"} currentTab
 */
window.avv_select_tab = (tabIdentification, currentTab = avv_get_current_tab()) => {

  setURLTab(tabIdentification)

  if(tabIdentification === currentTab) return;

  avv_before_select_tab(tabIdentification, currentTab)
  const activeContent = document.querySelector(".tab-content .tab-pane.active")
  const newContent = document.querySelector(`#${tabIdentification}`)

  activeContent?.classList.remove("active")
  newContent?.classList.add("active")
  avv_select_tab_listeners.forEach(fnc => fnc(tabIdentification))
}

/**
 * @param {"questionnaire" | "document" | "operations" | "doc-rules" | "diagnostics" | "livedemo" | "notes" | "advanced" | "settings"} tabIdentification
 * */

window.avv_get_current_tab = () => {
  return document.querySelector("#sidebar-wrap .active-tab")?.getAttribute("aria-controls") ?? 'document'
}

/**
 * @param {function("questionnaire" | "document" | "operations" | "doc-rules" | "diagnostics" | "livedemo" | "notes" | "advanced" | "settings"):void} fnc
 */
window.avv_listen_on_select_tab = (fnc) => {
  avv_select_tab_listeners.push(fnc);
}

/**
 * @param {function("questionnaire" | "document" | "operations" | "doc-rules" | "diagnostics" | "livedemo" | "notes" | "advanced" | "settings"):void} fnc
 */
window.avv_listen_on_close_tab = (fnc) => {
  avv_close_tab_listeners.push(fnc);
}

window.avv_before_select_tab = (tabIdentification, currentTab) => {
  avv_before_select_tab_listeners.forEach(fnc => fnc(tabIdentification, currentTab))
}

window.avv_listen_on_before_select_tab = (fnc) => {
  avv_before_select_tab_listeners.push(fnc)
}

const register_reactive_fields = (fieldElements: HTMLElement[]) => {
  fieldElements.forEach(element => {
    const reactiveId = element.getAttribute("reactive-value")
    if(!reactiveId) throw new Error('Reactive value is not defined')
    const type = element.getAttribute("type") || "text"
    switch(type) {
      case "text":
        const SET_ID = `SET_${reactiveId.toUpperCase()}`
        element.addEventListener("input", e => {
          AvvStore.commit(SET_ID, e.target.value)
        })

        if(AvvStore._mutations[SET_ID] === undefined)
          AvvStore._mutations[SET_ID] = [(payload) => {AvvStore.state[reactiveId] = payload }]

        /** Receive changes from other places */
        AvvStore.watch((state, getters) => state[reactiveId], (value, oldValue) => {
          element.value = value
        },  {immediate: true}) // immediately to setup the proper value on page load
        break;
    }
  })
}

let formIsSubmitting = false
let formIsHandled = false

export function appendFootnotes(editorHtml: string) {
  editorHtml += '<avv-footnotes>'
  getObjectEntries(EditorFactory.mainEntry.bridge!.footnotes).forEach(([key, value]) => {
    editorHtml += `<avv-footnote data-id="${key}">${value}</avv-footnote>`
  })
  editorHtml += '</avv-footnotes>'
  return editorHtml
}

export function deleteFootnotes(editorHtml: string) {
  // Extract footnotes and remove them from the editorHtml
  const footnotes = editorHtml.match(/<avv-footnotes>([\s\S]*?)<\/avv-footnotes>/g)
  if(footnotes) {
    editorHtml = editorHtml.replace(footnotes[0], "")

    // Extract each footnote and set it to entry.bridge.footnotes
    const footnoteRegex = /<avv-footnote data-id="([^"]*?)">([\s\S]*?)<\/avv-footnote>/g
    let match
    while ((match = footnoteRegex.exec(footnotes[0])) !== null) {
      if (match.index === footnoteRegex.lastIndex) {
        footnoteRegex.lastIndex++;
      }

      const id = match[1]
      const value = match[2]
      EditorFactory.mainEntry.bridge!.footnotes[id] = value
    }
  }
  return editorHtml
}

// Listen on submit of the form
const handle_save_template_button = () => {

  const form = document.forms["template-form"] as HTMLFormElement

  // Prevent submitting the form at different places
  if(form == null) return

  // Prevent handling the form multiple times
  if(formIsHandled) return
  formIsHandled = true

  enum Party {
    Representative,
    Name = 2,
    Permissions = 3
  }

  // We want to open some tabs when the form is submitted
  // We do this to call initialization methods of the tabs
  const reopenTabs = () => {
    const currTab = avv_get_current_tab()

    if(typeof WFStore === "undefined"){ // ensure WFStore is defined (it is instanciated on selecting workflow tab
      avv_select_tab('workflow')
      avv_select_tab(currTab, "workflow")
    }
  }

  const createInput = (name: string, value: any) => {
    const input = document.createElement("input")
    input.setAttribute("name", name);
    input.setAttribute("value", String(value));
    input.style.display = "none"
    form.appendChild(input);
  }

  // Reopen the tabs when the form is submitted
  reopenTabs();

  // Prevent doing anything else when the form is submitted
  form.addEventListener("keydown", (e: Event) => {
    if(formIsSubmitting) e.preventDefault()
  })

  // When the form is submitted
  form.addEventListener("submit",  (e: Event) => {
    const cancelSubmit = () => {
      stopAndPrevent(e)

      formIsSubmitting = false
    }

    try {
      // Prevent multiple submissions
      if(formIsSubmitting) return cancelSubmit();

      formIsSubmitting = true

      // Show the loading indicator when the form is submitted
      LoadingHelpers.startLoading(undefined, undefined, 'Saving your template')

      generateQuestionnaire();
      AvvStore.commit('SANITIZE_QUESTIONS')

      reopenTabs();

      if(WFStore.state.currEditedRule) AvvStore.commit('SAVE_RULE', WFStore.state.currEditedRule)

      EditorFactory.main.stages.stop()

      // Build avv-format data
      const avvFormat = new window.AvvParser.AVVFormat();

      avvFormat.setup()
        .question(AvvStore.state.question_template)
        .endSetup()

      const questionnaires = AvvStore.state.questions.reduce((obj, question) => {
        if(obj[question.party] == null) obj[question.party] = []
        obj[question.party].push(question);
        return obj;
      }, Object.create(null))

      for(const questionnaire of Object.keys(questionnaires)) {
        const questions = questionnaires[questionnaire]
        const builder = avvFormat.questionnaire(questionnaire)

        let lastCondition = undefined
        questions.forEach(q => {
          const fn = builder[q.type];
          if(fn == null) {
            throw new Error(`Cannot save template: because type ${q.type} does not exist in questionnaire builder.`)
          }

          if(q.cond !== lastCondition) {
            if(lastCondition != null) {
              builder.endCondition();
              lastCondition = null;
            }
            if(q.cond !== undefined) {
              lastCondition = q.cond;
              builder.condition(q.cond);
            }
          }

          fn.call(builder, q)
        })
        if(lastCondition != null) builder.endCondition()
        builder.endQuestionnaire();
      }

      const operations = avvFormat.operations();
      for(const operation of AvvStore.state.operations) {
        operations.operation(operation)
      }
      operations.endOperations();

      const startDocumentParty = AvvStore.state.start_document_party

      AvvStore.getters.parties.forEach(party => {
        const representative = party[Party.Representative].trim()
        const name = party[Party.Name].trim()
        const permissions = party[Party.Permissions];

        createInput("template[participants][][company]", window.AvvParser.encode(name))
        createInput("template[participants][][name]", window.AvvParser.encode(representative))
        createInput("template[participants][][edit]", permissions.edit)
        createInput("template[participants][][sign]", permissions.sign)
        createInput("template[participants][][approve]", permissions.approve)
        createInput("template[participants][][invite]", permissions.invite)
        if(startDocumentParty[0].trim() === name && startDocumentParty[1].trim() === representative) {
          createInput("template[participants][][me]", "on")
        }
        createInput("template[participants][][lock_email]", !!permissions.lock_email)
        createInput("template[participants][][default_email]", permissions.default_email || "")
        createInput("template[participants][][default_name]", permissions.default_name || "")
        createInput("template[participants][][force_add]", "send")
        createInput("template[participants][][allowed_email_domains]", permissions.allowed_email_domains)
        createInput("template[participants][][follow_user_profiles_rights]", permissions.follow_user_profiles_rights)
        createInput("template[participants][][default_role]", !!permissions.default_role)
        createInput("template[participants][][manage_comments]", !!permissions.manage_comments)
      })

      createInput('template[rules]', JSON.stringify(WFStore.getters.sanitizedRules))

      createInput('tab', avv_get_current_tab())
      createInput('template[versions_attributes][0][auto_name]', AvvStore.state.template_auto_name)
      createInput('template[versions_attributes][0][settings][allowed_webhook_entry_triggers]', JSON.stringify(AvvStore.state.allowed_webhook_triggers))
      createInput('template[versions_attributes][0][settings][disabled_system_notifications]', JSON.stringify(AvvStore.getters.normalizedDisabledSystemNotifications))

      EditorFactory.main.query(Query<Blot>('avvToc')).forEach(blot => delete blot.attributes['title'])
      EditorFactory.main.stages.next();

      let editorHtml = deltaToSanitisedHtml()
      editorHtml = appendFootnotes(editorHtml)

      if(document.getElementById("template_options"))                                           document.getElementById("template_options")!.value            = JSON.stringify(AvvStore.getters.normalizedOptions)
      if(document.getElementById("template_document_body"))                                     document.getElementById("template_document_body")!.value      = editorHtml
      if(avvFormat.hasQuestionnaire && document.getElementById("template_questionnaire_body"))  document.getElementById("template_questionnaire_body")!.value = avvFormat.format.substring(avvFormat.format.indexOf(".questionnaire"), avvFormat.format.lastIndexOf(".end questionnaire") + ".end questionnaire".length)
      if(avvFormat.hasSetup && document.getElementById("template_setup"))                       document.getElementById("template_setup")!.value              = avvFormat.format.substring(avvFormat.format.indexOf(".setup"), avvFormat.format.lastIndexOf(".end setup") + ".end setup".length)
      if(avvFormat.hasOperations && document.getElementById("template_calculations"))           document.getElementById("template_calculations")!.value       = avvFormat.format.substring(avvFormat.format.indexOf(".calculations"), avvFormat.format.lastIndexOf(".end calculations") + ".end calculations".length)
    } catch (error) {
      console.error(error);
      avv_dialog({alertMessage: 'There was an error saving the template. Please contact support and send them this message (or a screenshot): ' + error.message + '\n\n' + error.stack})
      cancelSubmit()
    } finally {
      formIsSubmitting = false
    }
  })

}

const handle_tab_select = () => {
  const tab = getURLTab()
  if(!tab) return
  avv_select_tab(tab)
}

const prepareCreateDocumentButton = function () {
  const createDocumentButton = document.getElementById('create-document-from-template')?.querySelector<HTMLElement>('.avv-button')
  if (!createDocumentButton) return

  createDocumentButton.addEventListener('click', (event) => {
    if(createDocumentButton.classList.contains('disabled')) {
      return stopAndPrevent(event)
    }

    validationDialog((valid) => {
      if (valid) {
        if (!event.metaKey && !event.ctrlKey) {
          const handleLoading = (show: boolean) => {
            const loadingElem = document.querySelector('.loading-screen') as HTMLElement

            document.querySelector('.loading-screen_text').textContent = 'Launching your document'

            loadingElem.style.display = show ? 'flex' : 'none'
            loadingElem.style.opacity = show ? '100' : '0'
          }

          handleLoading(true)
        } else {
          avv_dialog({
            snackStyle: 'notice',
            snackMessage: 'Launching your document in a new tab'
          })
        }
      } else {
        stopAndPrevent(event)
      }
    }, false, false)
  })
}

const prepareSaveTemplateButton = function (id: string, publish: boolean) {
  const button = document.getElementById(id)
  if (!button) return

  button.addEventListener('click', (event) => {
    validationDialog((valid) => {
      if (valid) {
        const strings = publish ? {
          confirmTitle: localizeText('template.dialog.publish.title'),
          confirmMessage: localizeText('template.dialog.publish.message'),
          okButtonText: localizeText('template.dialog.publish.title'),
          inputLabel: localizeText('template.dialog.publish.prompt')
        } : {
          confirmTitle: localizeText('template.dialog.save.title'),
          confirmMessage: localizeText('template.dialog.save.message'),
          okButtonText: localizeText('template.dialog.save.title'),
          inputLabel: localizeText('template.dialog.save.prompt')
        }

        avv_dialog({
          ...strings,
          confirmCallback: (value: boolean, inputValue?: string) => {
            if (value) {
              getElementByIdStrict<HTMLInputElement>('template_published').value = publish ? 'true' : 'false'
              getElementByIdStrict<HTMLInputElement>('template_publish_message').value = inputValue ?? ''
              getElementByIdStrict('submit-button').click()
            }
          }
        })
      } else {
        stopAndPrevent(event)
      }
    }, !publish, true)
  })
}

window.addEventListener('load', function () {
  register_reactive_fields(document.querySelectorAll("input[reactive-value]"))

  handle_save_template_button();

  handle_tab_select()

  prepareCreateDocumentButton()
  prepareSaveTemplateButton('save-template', false)
  prepareSaveTemplateButton('publish-template', true)
})
