import type { ISelectOption } from "../../features/QuestionnaireUtils";
import type { IWorkflow, IWorkflowRecipient } from "./workflow";

export enum ReportSeverity {
  Information,
  Warning,
  Error
}

export type ReportFunction = (severity: ReportSeverity, message: string) => void

export function validateTemplate (report: ReportFunction) {
  const state = AvvStore.state

  const validateAllowedDomains = (): boolean => {
    const allowedDomains = AvvStore.getters.parties.map(party => party[3].allowed_email_domains).filter(e => e && e !== 'undefined') as string[]

    return allowedDomains.every((domains) => {
      const parsedDomains = domains.split(',').map(domain => domain.trim())

      return parsedDomains.every(domain => /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/.test(domain))
    })
  }

  window.generateQuestionnaire()

  AvvStore.commit('SANITIZE_QUESTIONS')

  // Disallow to save when there is no template name
  if (!state.template_name) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_name'))
  }

  // Disallow to save when there is no defined party
  if (AvvStore.getters.parties.length <= 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_party'))
  }

  // Disallow to save party with empty/invalid value
  if (AvvStore.getters.parties.map((arr) => arr[2]).some(pName => pName == null || pName.length <= 0)) {
    report(ReportSeverity.Error, localizeText('template.err_messages.empty_invalid_party'))
  }

  // Disallow to save representative with empty/invalid value
  if (AvvStore.getters.parties.map((arr) => arr[0]).some(repName => repName == null || repName.length <= 0)) {
    report(ReportSeverity.Error, localizeText('template.err_messages.empty_invalid_role'))
  }

  // Disallow party and representative to be the same
  if (AvvStore.getters.parties.some((arr) => arr[2] === arr[0])) {
    report(ReportSeverity.Error, localizeText('template.err_messages.same_party_role'))
  }

  // Disallow to save if there is no any "Start document as this user" checked
  if(state.start_document_party.length <= 0 || state.start_document_party.reduce<string>((a, curr) => a += curr, "").length <= 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_author'))
  }

  const getPartiesWithoutController = () => {
    return Ast.uniqueArray(AvvStore.getters.parties.filter((party) => AvvStore.getters.parties.filter(p => p[2] === party[2]).every(participant => participant[3].approve === 'No')).map(p => p[2]))
  }

  const partiesWithoutController = getPartiesWithoutController()
  if (partiesWithoutController.length) {
    const isMultiParty = partiesWithoutController.length > 1
    const joinedPartyString = partiesWithoutController.join(' and ')
    const verb = isMultiParty ? 'are' : 'is'

    report(ReportSeverity.Error, `${joinedPartyString} ${verb} missing a controller`)
  }

  if (!validateAllowedDomains()) {
    report(ReportSeverity.Error, localizeText('template.err_messages.invalid_domain'))
  }

  const attributes = AvvStore.getters.attributes.filter(att => att.length > 255)
  if (attributes.length) {
    report(ReportSeverity.Error, localizeText('template.err_messages.long_att', {attrs: attributes.map(attr => `\`${attr}\``).join(' ,')}))
  }
}

export function validateQuestions (report: ReportFunction, questionScope: Backend.Questionnaire.IQuestion[]) {
  const state = AvvStore.state

  // Validate label questions
  if (questionScope.filter((question) => question.type !== 'label' && question.type !== 'section').some((question) => !question.att)) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_q'))
  }

  // Validate metadata questions
  const metadataQuestionsWithoutKey = questionScope.filter((question) => question.type === 'metadata' && !question.opts.metadata_key).map(question => question.att)
  if(metadataQuestionsWithoutKey.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.metadata', {attrs: metadataQuestionsWithoutKey.join(', ')}))
  }

  // Validate select-type questions
  const selectQuestions = questionScope.filter((question) => ['select', 'open_select', 'multi_select'].includes(question.type))

  const attributesWithMissingOptionValue = selectQuestions.filter((question) => {
    const options = question.opts.selectOptions as ISelectOption[]
    return options && options.filter((option) => !option.value).length > 0
  }).map((question) => question.att).join(', ')

  if (attributesWithMissingOptionValue.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_option_value', { attributes: attributesWithMissingOptionValue }))
  }

  const attributesWithMissingOptionLabel = selectQuestions.filter((question) => {
    const options = question.opts.selectOptions as ISelectOption[]
    return options && options.filter((option) => option.type === 'collect' && option.label_type === 'static' && !option.label).length > 0
  }).map((question) => question.att).join(', ')

  if (attributesWithMissingOptionLabel.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_option_label', { attributes: attributesWithMissingOptionLabel }))
  }

  const attributesWithDuplicateOptionValue = selectQuestions.filter((question) => {
    const options = question.opts.selectOptions as ISelectOption[]
    return options && options.filter((option) => option.value && options.filter((opt) => opt.type === option.type && opt.value === option.value).length > 1).length > 0
  }).map((question) => question.att).join(', ')

  if (attributesWithDuplicateOptionValue.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_option_value_uniq', { attributes: attributesWithDuplicateOptionValue }))
  }

  const attributesWithDuplicateOptionLabel = selectQuestions.filter((question) => {
    const options = question.opts.selectOptions as ISelectOption[]
    return options && options.filter((option) => {
      const filteredOptions = option.type !== 'collect' || option.label_type === 'static' ?
        options.filter((opt) => (opt.type !== 'collect' || opt.label_type === 'static') && (opt.label || opt.value) === (option.label || option.value)) :
        options.filter((opt) => (opt.type === 'collect' && opt.label_type !== 'static') && (opt.label || opt.value) === (option.label || option.value))

      return filteredOptions.length > 1
    }).length > 0
  }).map((question) => question.att).join(', ')

  if (attributesWithDuplicateOptionLabel.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_option_label_uniq', { attributes: attributesWithDuplicateOptionLabel }))
  }

  // Validate datasheet questions
  const datasheetsQuestions = questionScope.filter((question) => question.type === 'datasheets')

  const attributesWithInvalidDatasheet = datasheetsQuestions.filter((question) => {
    const datasheet_id = question.opts.datasheet_id
    return state.authorized_datasheet_ids.findIndex((id) => id == datasheet_id) === -1
  }).map((question) => question.att).join(', ')

  if (attributesWithInvalidDatasheet.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_datasheet_id', { attributes: attributesWithInvalidDatasheet }))
  }

  // Validate list questions
  const listQuestions = questionScope.filter((question) => question.type === 'listSelectDb')

  const attributesWithInvalidList = listQuestions.filter((question) => {
    const name = question.opts.listSelectDbName
    return !state.templates_lists[name]
  }).map((question) => question.att).join(', ')

  if (attributesWithInvalidList.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_list', { attributes: attributesWithInvalidList }))
  }
  
  // Validate dependent list questions
  const dependentListQuestions = questionScope.filter((question) => question.type === 'dependentList')

  const attributesWithInvalidDependentList = dependentListQuestions.filter((question) => {
    const name = question.opts.dependentListName
    return !state.templates_dependent_lists[name]
  }).map((question) => question.att).join(', ')

  if (attributesWithInvalidDependentList.length > 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_dependent_list', { attributes: attributesWithInvalidDependentList }))
  }
}

export function validateWorkflows (report: ReportFunction, workflowScope: IWorkflow[]) {
  const state = AvvStore.state
  const attributes = AvvStore.getters.attributes

  // Ensure WFStore is defined
  if (typeof WFStore === "undefined"){
    const currentTab = avv_get_current_tab()

    avv_select_tab('workflow')
    avv_select_tab(currentTab)
  }

  // Generic validators
  const validateRecipient = (recipient: IWorkflowRecipient) => {
    if (!recipient) return true
    else if ('role' in recipient) return !(recipient.role && recipient.party)
    else if ('name_attribute' in recipient) return !(recipient.name_attribute && recipient.email_attribute)
    else if ('user_id' in recipient) return !recipient.user_id
    else if ('name' in recipient) return !(recipient.name && recipient.email)
  }

  // Validators
  const validateAddRemoveDocumentLabelRule = (rule: IWorkflow) => {
    const { labels } = rule.options
    if (labels.length === 0) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_remove_document_label.label_missing'))
      return true
    } else if (labels.some((id: number) => state.profileOrganisationLabels.findIndex(({ id: _id }) => _id == id) === -1)) {
      report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.add_remove_document_label.label_invalid'))
      return true
    }
  }

  const validateSaveDocumentVersionRule = (rule: IWorkflow) => {
    const restrictedTriggers = ['deleted', 'completed', 'signed', 'partially_signed', 'pending_external_signature']

    if (restrictedTriggers.includes(rule.trigger)) {
      report(ReportSeverity.Error, localizeText('workflow.validation.cannot_trigger_document_save', { workflow: rule.label }))
      return true
    }
  }

  const validateSendReminderEmailRule = (rule: IWorkflow) => {
    const { recipients, email_template_id } = rule.options.send_reminder_email ?? {}
    if (!Array.isArray(recipients) || recipients.length === 0) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.send_reminder_email.recipients_missing'))
      return true
    } else if (!email_template_id) {
      report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.send_reminder_email.template_missing'))
      return true
    } else if (recipients.some(validateRecipient)) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.send_reminder_email.recipients_invalid'))
      return true
    }
  }

  const validateSendDocumentRule = (rule: IWorkflow) => {
    const { recipients, email_template_id } = rule.options.send_document ?? {}
    if (!Array.isArray(recipients) || recipients.length === 0) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.send_document.recipients_missing'))
      return true
    } else if (!email_template_id) {
      report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.send_document.template_missing'))
      return true
    } else if (recipients.some(validateRecipient)) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.send_document.recipients_invalid'))
      return true
    }
  }

  const validateAddParticipantRule = (rule: IWorkflow) => {
    if (!rule.party_def) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.participant_missing'))
      return true
    } else if (WFStore.state.currEditedRule && (WFStore.getters.isApproverInitial !== WFStore.state.isApprover)) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.block_action_missing'))
      return true
    }

    const data = rule.options.pre_defined_participants
    const mode = data.mode
    const emailRegexp = /^[a-zA-Z0-9.!$%‘*+/=?^_`{|}~-]+@\w+([\.-]?\w+)*(\.\w{2,})+$/

    if (mode === 'default') {
      if (data.active) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.active_invalid'))
        return true
      }
    } else if (mode === 'list' || mode === 'auto_list') {
      if (!data.emails) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.email_missing'))
        return true
      } else if (data.emails.split(';').some((email: string) => !emailRegexp.test(email.trim()))) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.email_invalid'))
        return true
      }
    } else if (mode === 'contact_group' || mode === 'auto_contact_group') {
      if (!data.contact_group.contact_group_id) {
        report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.add_participant.contact_group_missing'))
        return true
      } else if (!state.activeContactGroups.some((contactGroup) => contactGroup.id == data.contact_group.contact_group_id)) {
        report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.add_participant.contact_group_invalid'))
        return true
      } else if (mode === 'contact_group' && !data.contact_group.pick_item) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.contact_group_pick_invalid'))
        return true
      }
    } else if (mode === 'auto_attribute') {
      if (!data.emails || !attributes.includes(data.emails)) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.email_attribute_missing'))
        return true
      } else if (!data.names || !attributes.includes(data.names)) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.add_participant.name_attribute_missing'))
        return true    
      }
    }
  }

  const validateCreateRelatedDocumentRule = (rule: IWorkflow) => {
    const { related_template_id, related_template_participant_count } = rule.options
    if (!related_template_id) {
      report(ReportSeverity.Warning, localizeText('template.err_messages.create_related_document.template_missing'))
      return true
    } else if (related_template_participant_count && related_template_participant_count != 0) {
      const data = rule.options.pre_defined_participants
      const mode = data.mode
      const emailRegexp = /^[a-zA-Z0-9.!$%‘*+/=?^_`{|}~-]+@\w+([\.-]?\w+)*(\.\w{2,})+$/

      if (!rule.party_def) {
        report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.create_related_document.party_role_missing'))
        return true
      } else if (mode === 'auto_list') {
        if (!data.emails) {
          report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.create_related_document.email_missing'))
          return true
        } else if (data.emails.split(';').some((email: string) => !emailRegexp.test(email.trim()))) {
          report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.create_related_document.email_invalid'))
          return true
        }
      } else if (mode === 'auto_contact_group') {
        if (!data.contact_group.contact_group_id) {
          report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.create_related_document.contact_group_missing'))
          return true
        } else if (!state.activeContactGroups.some((contactGroup) => contactGroup.id == data.contact_group.contact_group_id)) {
          report(ReportSeverity.Warning, localizeText('template.err_messages.workflow.rule.create_related_document.contact_group_invalid'))
          return true
        }
      } else if (mode === 'auto_attribute') {
        if (!data.emails || !attributes.includes(data.emails)) {
          report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.create_related_document.email_attribute_missing'))
          return true
        } else if (!data.names || !attributes.includes(data.names)) {
          report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule.create_related_document.name_attribute_missing'))
          return true    
        }
      }
    }
  }

  // Validator mapping
  const ruleValidators = {
    'Add Participant': validateAddParticipantRule,
    'Save document version': validateSaveDocumentVersionRule,
    'Send reminder email': validateSendReminderEmailRule,
    'Send Document': validateSendDocumentRule,
    'Add Document Label': validateAddRemoveDocumentLabelRule,
    'Remove Document Label': validateAddRemoveDocumentLabelRule,
    'Create related document': validateCreateRelatedDocumentRule
  }

  const validateApprovalStatusTrigger = (rule: IWorkflow) => {
    if (!rule.options.approval_trigger || rule.options.approval_trigger.length === 0 || rule.options.approval_trigger.some((opt) => !opt.workflow || typeof opt.status !== 'boolean')) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.trigger.approval_status.workflow_missing'))
      return true
    }
  }

  const validateLabelAddedRemovedTrigger = (rule: IWorkflow) => {
    if (!rule.options.trigger_label_id) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.trigger.label_added_removed.label_missing'))
      return true
    } else if (state.profileOrganisationLabels.findIndex(({ id: _id }) => _id == rule.options.trigger_label_id) === -1) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.trigger.label_added_removed.label_invalid'))
      return true
    }
  }

  const triggerValidators = {
    'approval_status': validateApprovalStatusTrigger,
    'label_removed': validateLabelAddedRemovedTrigger,
    'label_added': validateLabelAddedRemovedTrigger
  }

  const validateRule = (rule: IWorkflow) => {
    if (!rule.label) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.label_missing'))
      return true
    } else if (!rule.trigger) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.trigger_missing'))
      return true
    } else if (!rule.rule) {
      report(ReportSeverity.Error, localizeText('template.err_messages.workflow.rule_missing'))
      return true
    } else if (triggerValidators[rule.trigger]?.(rule)) {
      return true
    } else return ruleValidators[rule.rule]?.(rule)
  }

  // Use some method to short circuit
  workflowScope.some((rule) => validateRule(rule))
}

export const validationDialog = function (callback: (valid: boolean) => void, ignoreWarnings: boolean, validateCurrentWorkflow: boolean) {
  const detectedErrors: { message: string, severity: ReportSeverity }[] = []

  const report = (severity: ReportSeverity, message: string) => {
    detectedErrors.push({ severity, message })
  }

  // Validate generic template stuff
  validateTemplate(report)

  // Validate questions
  const questionScope = AvvStore.state.questions
  validateQuestions(report, questionScope)

  // Validate currently selected or all workflows depending on what action we want to carry out
  const workflowScope = validateCurrentWorkflow && WFStore.state.currEditedRule ? (Array.isArray(WFStore.state.currEditedRule) ? WFStore.state.currEditedRule : [WFStore.state.currEditedRule]) : AvvStore.state.rules
  validateWorkflows(report, workflowScope as IWorkflow[])

  if (detectedErrors.length > 0) {
    const messageEntries = detectedErrors.map(({ message, severity }) => {
      const icon = severity === ReportSeverity.Error ? 'error' : (severity === ReportSeverity.Warning ? 'warning' : 'info')

      return `<div><i class="material-symbols-outlined float-left mr-1" aria-hidden="true">${icon}</i> ${message}</div>`
    })

    const message = `
      <div class="flex flex-col gap-1" style="max-height: 60vh;">
        ${messageEntries.join('')}
      </div>
      <br>
    `

    if (ignoreWarnings && detectedErrors.every((error) => error.severity !== ReportSeverity.Error)) {
      avv_dialog({
        confirmTitle: localizeText('template.dialog.validation.title'),
        confirmMessage: message,
        okButtonText: localizeText('template.dialog.validation.continue'),
        confirmCallback: callback
      })
    } else {
      avv_dialog({
        alertTitle: localizeText('template.dialog.validation.title'),
        alertMessage: message
      })

      callback(false)
    }
  } else {
    callback(true)
  }
}
